/** Created by Tomáš Korec on 2019/10/22 */

/**
 * ROLES MANAGEMENT
 */
export const role = {
  OPERATOR: "operator",
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  BILLING: "billing",
  SUPPORT: "support",
  REALIZATION: "realization",
  SMS: "sms",
  PORTATION: "portation",
}

export const parsePermissions = permissions => ({
  [role.SUPERADMIN]: permissions.includes("provider_superadmin"),
  [role.ADMIN]: permissions.includes("provider_admin"),
  [role.BILLING]: permissions.includes("provider_fakturace"),
  [role.SUPPORT]: permissions.includes("provider_podpora"),
  [role.REALIZATION]: permissions.includes("provider_realizace"),
  [role.PORTATION]: permissions.includes("provider_portace"),
  [role.SMS]: permissions.includes("provider_sms"),
  [role.OPERATOR]: permissions.includes("operator"),
})

/**
 * ROLES UTILS
 */
export const hasRole = (userRoles, requiredRoles) => {
  if (typeof requiredRoles === "string") {
    return userRoles[requiredRoles]
  }
  return requiredRoles.some(role => userRoles[role])
}

export const hasSomeRole = user =>
  user && user.roles ? Object.values(user.roles).some(role => role) : false
